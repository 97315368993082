export default function CartOrderItemSkeleton() {
  return (
    <div className="flex w-full gap-[15px]">
      <div className="animate-pulse bg-neutral-200 w-[90px] h-[90px] rounded-lg" />
      <div className="flex flex-col justify-between flex-1 gap-[50px]">
        <div className="animate-pulse bg-neutral-200 w-full h-[40px] rounded-lg" />
        <div className="flex flex-col w-full gap-[5px]">
          <div className="animate-pulse bg-neutral-200 h-[20px] rounded-lg" />
          <div className="animate-pulse bg-neutral-200 h-[20px] rounded-lg" />
          <div className="animate-pulse bg-neutral-200 h-[20px] rounded-lg" />
        </div>
      </div>
    </div>
  );
}
