import Text from "@/components/v2/atoms/Text/Text";
import classNames from "classnames";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { useTranslation } from "react-i18next";

dayjs.extend(timezone);

type PreOrderTagProps = {
  isPreorder?: boolean;
  isBoldInProgress?: boolean;
};

export default function PreOrderTag({ isPreorder = undefined, isBoldInProgress = false }: PreOrderTagProps) {
  const { orderTimeSlot } = useOrderContextNew();
  const today = dayjs();
  const isSameDayOrder = today.isSame(orderTimeSlot, "day");
  const { t } = useTranslation("common");

  return (
    <div
      className={classNames("py-[4px] px-[10px] rounded-[5px] flex items-center justify-center h-min w-fit min-w-max", {
        hidden: isPreorder === undefined ? isSameDayOrder : !isPreorder,
        "bg-primary-light": !isBoldInProgress,
        "bg-primary": isBoldInProgress,
      })}
    >
      <Text variant="caption3">{isBoldInProgress ? t("preorder_in_progress_capitalized") : t("Preorder")}</Text>
    </div>
  );
}
