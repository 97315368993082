import React from "react";
import { useTranslation } from "react-i18next";
import Image from "next/image";
import classNames from "classnames";
import { ProductBoxImageType } from "@/generated/requests/pos";

interface CookieDynamicProductImageProps {
  numFlavors: number;
  selectedFlavors: Array<any>;
  isLargeProduct?: boolean;
  className?: string;
  isForProductDetail?: boolean;
  ariaLabel?: string;
  shouldFocus?: boolean;
  boxImages?: Array<any>;
}

export default function CookieDynamicProductImage({
  numFlavors,
  selectedFlavors,
  isLargeProduct = false,
  className = "",
  isForProductDetail = false,
  ariaLabel = "",
  shouldFocus = false,
  boxImages = [],
}: CookieDynamicProductImageProps) {
  const base = boxImages?.find((box) => box?.type === ProductBoxImageType.Base);
  const lid = boxImages?.find((box) => box?.type === ProductBoxImageType.Lid);
  return (
    <div
      className={classNames("w-full h-full md:rounded-2xl", className)}
      aria-label={ariaLabel}
      tabIndex={shouldFocus ? 0 : -1}
      style={{
        backgroundImage:
          boxImages?.find((box) => box?.type === ProductBoxImageType.Background)?.url &&
          `url(${boxImages?.find((box) => box?.type === ProductBoxImageType.Background)?.url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* When using this component, always remember this is a square, DO NOT CHANGE THE DIMENSION HERE! */}
      <div className="relative m-auto aspect-square h-full">
        <div className={classNames(!isForProductDetail && "-rotate-12", "w-full h-full")}>
          {numFlavors === 1 ? (
            <SingleCookieImage selectedFlavors={selectedFlavors} base={base} lid={lid} />
          ) : numFlavors === 3 ? (
            <ThreeCookieImage selectedFlavors={selectedFlavors} base={base} lid={lid} />
          ) : numFlavors === 4 ? (
            <FourCookiesImage selectedFlavors={selectedFlavors} base={base} lid={lid} />
          ) : numFlavors === 6 ? (
            <SixCookiesImage selectedFlavors={selectedFlavors} base={base} lid={lid} />
          ) : (
            numFlavors === 12 && !isLargeProduct && <TwelveMiniCookiesImage selectedFlavors={selectedFlavors} />
          )}
        </div>
        <div>
          {numFlavors === 12 && isLargeProduct && (
            <TwelveCookiesImage
              selectedFlavors={selectedFlavors}
              isForProductDetail={isForProductDetail}
              base={base}
              lid={lid}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function SingleCookieImage({ selectedFlavors, base, lid }) {
  const { t } = useTranslation();
  const flavorOption = selectedFlavors?.[0];
  return (
    <div className="relative w-full h-full">
      {base ? (
        <img src={base?.url} alt="" width={400} height={400} className="w-full h-full" />
      ) : (
        <DynamicImage
          srcMobile={`/images/v2/cookie-box-1.png`}
          srcDesktop={`/images/v2/cookie-box-1.svg`}
          alt={t("order:box_of_cookies")}
        />
      )}
      {selectedFlavors?.length > 0 && (
        <div className="absolute top-[51%] left-1/2 -translate-x-1/2 -translate-y-1/2 h-[35%] aspect-square">
          <CookieImage src={flavorOption?.image} alt={t(flavorOption?.name)} />
        </div>
      )}
      {lid ? (
        <img
          src={lid?.url}
          alt=""
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full"
          width={400}
          height={400}
        />
      ) : (
        <DynamicImage
          srcMobile={`/images/v2/cookie-box-lid-1.png`}
          srcDesktop={`/images/v2/cookie-box-lid-1.svg`}
          alt={t("order:box_of_cookies_lid")}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      )}
    </div>
  );
}

function ThreeCookieImage({ selectedFlavors, base, lid }) {
  const { t } = useTranslation();
  return (
    <div className="relative w-full h-full">
      {base ? (
        <img src={base?.url} alt="" width={400} height={400} className="w-full h-full" />
      ) : (
        <DynamicImage
          srcMobile={`/images/v2/cookie-box-3.png`}
          srcDesktop={`/images/v2/cookie-box-3.svg`}
          alt={t("order:box_of_cookies")}
        />
      )}
      <div className="grid grid-cols-3 absolute top-[56%] left-[48.5%] -translate-x-1/2 -translate-y-1/2 w-[75%]">
        {selectedFlavors?.map((flavor, index) => (
          <div key={index} className="flex items-center justify-center w-full h-full aspect-square">
            <CookieImage src={flavor?.image} alt={t(flavor?.name)} />
          </div>
        ))}
      </div>
      {lid ? (
        <img
          src={lid?.url}
          alt=""
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full"
          width={400}
          height={400}
        />
      ) : (
        <DynamicImage
          srcMobile={`/images/v2/cookie-box-lid-3.png`}
          srcDesktop={`/images/v2/cookie-box-lid-3.svg`}
          alt={t("order:box_of_cookies_lid")}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      )}
    </div>
  );
}

function FourCookiesImage({ selectedFlavors, base, lid }) {
  const { t } = useTranslation();
  return (
    <div className="relative w-full h-full">
      {base ? (
        <img src={base?.url} alt="" width={400} height={400} className="w-full h-full" />
      ) : (
        <DynamicImage
          srcMobile={`/images/v2/cookie-box-4.png`}
          srcDesktop={`/images/v2/cookie-box-4.svg`}
          alt={t("order:box_of_cookies")}
        />
      )}
      <div className="grid grid-cols-4 absolute top-[53%] left-1/2 -translate-x-1/2 -translate-y-1/2 w-[78%]">
        {selectedFlavors?.map((flavor, index) => (
          <div key={index} className="flex items-center justify-center w-full h-full aspect-square">
            <CookieImage src={flavor?.image} alt={t(flavor?.name)} />
          </div>
        ))}
      </div>
      {lid ? (
        <img
          src={lid?.url}
          alt=""
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full"
          width={400}
          height={400}
        />
      ) : (
        <DynamicImage
          srcMobile={`/images/v2/cookie-box-lid-4.png`}
          srcDesktop={`/images/v2/cookie-box-lid-4.svg`}
          alt={t("order:box_of_cookies_lid")}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      )}
    </div>
  );
}

function SixCookiesImage({ selectedFlavors, base, lid }) {
  const { t } = useTranslation();
  return (
    <div className="relative w-full h-full">
      {base ? (
        <img src={base?.url} alt="" width={400} height={400} className="w-full h-full" />
      ) : (
        <DynamicImage
          srcMobile={`/images/v2/cookie-box-6.png`}
          srcDesktop={`/images/v2/cookie-box-6.svg`}
          alt={t("order:box_of_cookies")}
        />
      )}
      <div className="grid grid-cols-3 grid-rows-2 absolute top-[54%] left-1/2 -translate-x-1/2 -translate-y-1/2 w-[70%] h-[40%]">
        {selectedFlavors?.slice(0, 6).map((flavor, index) => (
          <div key={index} className="flex items-center justify-center w-full h-full aspect-square">
            <CookieImage src={flavor?.image} alt={t(flavor?.name)} />
          </div>
        ))}
      </div>
      {lid ? (
        <img
          src={lid?.url}
          alt=""
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full"
          width={400}
          height={400}
        />
      ) : (
        <DynamicImage
          srcMobile={`/images/v2/cookie-box-lid-6.png`}
          srcDesktop={`/images/v2/cookie-box-lid-6.svg`}
          alt={t("order:box_of_cookies_lid")}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      )}
    </div>
  );
}

function TwelveCookiesImage({ selectedFlavors, isForProductDetail, base, lid }) {
  return (
    <div className="w-full h-full">
      {/* This is for xl view */}
      <div className={classNames(isForProductDetail ? "hidden xl:block" : "block")}>
        <div className="absolute -top-[15%] -left-[12%] w-full h-full -rotate-12 scale-75">
          <SixCookiesImage selectedFlavors={selectedFlavors?.slice(0, 6)} base={base} lid={lid} />
        </div>
        <div className="absolute top-[15%] left-[16%] w-full h-full -rotate-12 scale-75">
          <SixCookiesImage selectedFlavors={selectedFlavors?.slice(6, 12)} base={base} lid={lid} />
        </div>
      </div>
      {/* this is for sm and mid view  */}
      <div className={classNames(isForProductDetail ? "xl:hidden" : "hidden")}>
        <div className="absolute -top-[3%] -left-[38%] w-full h-full -rotate-12">
          <SixCookiesImage selectedFlavors={selectedFlavors?.slice(0, 6)} base={base} lid={lid} />
        </div>
        <div className="absolute top-[3%] left-[38%] w-full h-full -rotate-12">
          <SixCookiesImage selectedFlavors={selectedFlavors?.slice(6, 12)} base={base} lid={lid} />
        </div>
      </div>
    </div>
  );
}

function TwelveMiniCookiesImage({ selectedFlavors }) {
  const { t } = useTranslation();
  return (
    <div className="relative w-full h-full">
      <DynamicImage
        srcMobile={`/images/v2/cookie-box-mini-12.png`}
        srcDesktop={`/images/v2/cookie-box-mini-12.svg`}
        alt={t("order:box_of_cookies")}
      />
      <div className="grid grid-cols-4 grid-rows-3 absolute top-[51%] sm:top-[54%] left-[50.5%] -translate-x-1/2 -translate-y-1/2 w-[66%] h-[50%]">
        {selectedFlavors?.map((flavor, index) => (
          <div key={index} className="flex items-center justify-center w-full h-full aspect-square">
            <CookieImage src={flavor?.image} alt={t(flavor?.name)} />
          </div>
        ))}
      </div>
      <DynamicImage
        srcMobile={`/images/v2/cookie-box-lid-mini-12.png`}
        srcDesktop={`/images/v2/cookie-box-lid-mini-12.svg`}
        alt={t("order:box_of_cookies_lid")}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      />
    </div>
  );
}

function CookieImage({ src, alt }) {
  return (
    <Image src={src} alt={alt} width={400} height={400} className={`animate-fadeIn w-full h-full object-contain`} />
  );
}

function DynamicImage({ srcMobile, srcDesktop, alt, className = "" }) {
  return (
    <>
      <img src={srcMobile} alt={alt} className={`sm:hidden w-full h-full ${className}`} />
      <img src={srcDesktop} alt={alt} className={`hidden sm:block w-full h-full ${className}`} />
    </>
  );
}
