import type { SourceType } from "@/generated/requests/pos";
import { useRouter } from "next/router";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import CartOrderItem from "../../molecules/CartOrderItem/CartOrderItem";
import { useGetSource, useGetStoreBySlug } from "../../operations/queries";
import dayjs from "dayjs";
import Divider from "../../atoms/Divider/Divider";
import CartOrderItemSkeleton from "../../molecules/CartOrderItem/CartOrderItemSkeleton";

export default function CartOrderItemsList({
  setShowToast,
  hideQuantitySelector = false,
}: { setShowToast?: (showToast: boolean) => void; hideQuantitySelector?: boolean }) {
  const router = useRouter();
  const { order, upsertOrderItems, setLastRemovedItem, orderTimeSlot, setTimeSlot } = useOrderContextNew();
  const storeSlug = router.query.slug as string;
  const orderType = router.query.type as string;
  const orderSourceType = orderType?.toUpperCase() as SourceType;
  const { data: storeData } = useGetStoreBySlug({ storeSlug });
  // Sending people to the right time slot to order
  router.query?.date && typeof router.query.date === "string" && setTimeSlot(dayjs(router.query.date).toISOString());
  const { data: sourceProductsData, loading: sourceProductsLoading } = useGetSource({
    storeId: storeData?.storeId || order?.storeId,
    type: orderSourceType || order?.source?.type,
    timeSlot: orderTimeSlot,
  });
  const rewardProductIdsInCart = order?.rewardProducts?.map((item) => item?.product?.productId);

  // only one product can be used for each reward at a time
  const usedRewardProductIds = new Set();
  const usedVoucherIds = new Set();

  const handleUpdateCartItemQuantity = async (
    newQuantity: number,
    itemInCart: any,
    cartItemIndex: number,
    isCoveredByReward = false,
    isVouchered = false,
  ) => {
    let items = [];
    let vouchers = order?.vouchers;
    let rewardProducts = order?.rewardProducts;
    if (newQuantity <= 0) {
      items = order?.items
        ?.filter((_, i) => i !== cartItemIndex)
        ?.map((item) => ({
          ...item,
          productId: item?.product?.productId,
          quantity: item?.quantity,
        }));
      if (isCoveredByReward || isVouchered) {
        vouchers = order?.vouchers?.filter((voucher) => voucher?.productId !== itemInCart?.product?.productId);
        rewardProducts = order?.rewardProducts?.filter(
          (reward) => reward?.product?.productId !== itemInCart?.product?.productId,
        );
      }
    } else {
      items = order?.items?.map((item, i) => {
        if (i === cartItemIndex) {
          return {
            ...item,
            productId: item?.product?.productId,
            quantity: newQuantity || 0,
          };
        }
        return {
          ...item,
          productId: item?.product?.productId,
          quantity: item?.quantity,
        };
      });
    }

    await upsertOrderItems({
      order,
      items,
      vouchers,
      rewardProducts,
    });
    if (newQuantity <= 0) {
      setLastRemovedItem(itemInCart);
      setShowToast(true);
    }
  };

  return (
    <div className="flex flex-col gap-[15px]">
      {order?.items?.map((item, i) => {
        const product = sourceProductsData?.public?.sourceForStore?.products?.find(
          (p) => p.product?.productId === item.product.productId,
        );
        const reward = order?.rewardProducts?.find(
          (reward) => reward?.product?.productId === product?.product?.productId,
        );
        const voucheredItem = order?.vouchers?.find((voucher) => voucher?.productId === product?.product?.productId);

        const isCoveredByReward =
          rewardProductIdsInCart?.includes(product?.product?.productId) &&
          !usedRewardProductIds?.has(reward?.rewardProductId);

        // Check if the voucher has already been used
        const isVoucherUsed = usedVoucherIds?.has(voucheredItem?.voucherId);

        // If the reward is covered and the voucher has not been used yet
        if (isCoveredByReward) {
          usedRewardProductIds?.add(reward.rewardProductId);
        }
        // Only apply the voucher if it hasn't been used, and reward is not covering the item
        const isVouchered = voucheredItem && !isVoucherUsed && !isCoveredByReward;
        if (isVouchered) {
          usedVoucherIds?.add(voucheredItem.voucherId);
        }

        return sourceProductsLoading ? (
          <CartOrderItemSkeleton key={i} />
        ) : (
          <div key={i} className="flex flex-col gap-[15px]">
            <CartOrderItem
              item={item}
              itemIndex={i}
              updateQuantity={(newQuantity) =>
                handleUpdateCartItemQuantity(newQuantity, item, i, isCoveredByReward, isVouchered)
              }
              editGiftWrapModifierForItem={() => {}}
              reward={isCoveredByReward && reward}
              isVouchered={isVouchered}
              hideQuantitySelector={hideQuantitySelector}
            />
            {i !== order?.items?.length - 1 && <Divider />}
          </div>
        );
      })}
    </div>
  );
}
