import { useClickOff } from "@/components/v2/hooks/useClickOff";
import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import type React from "react";
import { useEffect, useMemo } from "react";

export type SlideOutProps = {
  visible: boolean;
  from?: "left" | "right";
  className?: string;
  onDismiss?: () => void;
  children: React.ReactNode;
};

export function SlideOut({ visible, from = "left", className, onDismiss, children }: SlideOutProps) {
  const navRef = useClickOff<HTMLElement>(() => onDismiss?.());

  // Set navbar invisible when not open to prevent tabbing through the menu
  // items here. Normally, I'm opposed to using `setTimeout`, but I tried doing
  // this with a `<motion.nav>` relying on the `onAnimation*` event handlers and
  // ran into issues where the navbar animates in/out and then flickers and
  // repeats the animation. Maybe try again if somebody is bored?
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (visible) {
      navRef.current?.classList.remove("invisible");
    } else {
      timeout = setTimeout(() => {
        navRef.current?.classList.add("invisible");
      }, 300);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [visible, navRef]);

  const orientationStyles = useMemo(
    () =>
      classNames(
        visible && from === "left" && "-translate-x-0",
        !visible && from === "left" && "-translate-x-full",
        visible && from === "right" && "translate-x-0",
        !visible && from === "right" && "translate-x-full",
        from === "left"
          ? "left-0 sm:rounded-r-3xl shadow-[5px_0_20px_0_rgba(0,0,0,0.05)]"
          : "right-0 sm:rounded-l-3xl shadow-[-5px_0_20px_0_rgba(0,0,0,0.05)]",
      ),
    [from, visible],
  );

  return (
    <FocusTrap active={visible} focusTrapOptions={{ allowOutsideClick: true }}>
      <nav
        ref={navRef}
        className={classNames(
          "fixed z-40 bottom-0 bg-white h-full overflow-x-hidden",
          "transition-transform duration-300",
          orientationStyles,
          className,
        )}
      >
        {children}
      </nav>
    </FocusTrap>
  );
}
