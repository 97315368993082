import classNames from "classnames";
import { useTranslation } from "next-i18next";
import IconMinusFilled from "../../atoms/Icons/MinusFilled";
import IconPlusFilled from "../../atoms/Icons/PlusFilled";

interface QuantitySelectionButtonProps {
  quantity: number;
  onDecrement?: () => void;
  onIncrement?: () => void;
  iconForDecrement?: any;
  className?: string;
  isLoading?: boolean;
}
export default function QuantitySelectionButton({
  quantity,
  onDecrement,
  onIncrement,
  iconForDecrement,
  className,
  isLoading = false,
}: QuantitySelectionButtonProps) {
  const { t } = useTranslation("order");

  return (
    <div
      className={classNames(
        "flex-nowrap inline-flex items-center justify-between min-h-[40px] md:min-h-[50px] min-w-[110px] md:min-w-[159px] rounded-full py-3 bg-white text-black border-[1px] border-opacity-20",
        {
          "opacity-20": isLoading,
        },
        className,
      )}
    >
      <button
        className={classNames("p-2 md:p-4", { "text-gray-400": !onDecrement })}
        onClick={onDecrement}
        data-testid="minus-button"
        disabled={isLoading || !onDecrement}
        aria-label={iconForDecrement ? t("delete_item") : t("decrease_quantity")}
        role="button"
      >
        {iconForDecrement || <IconMinusFilled />}
      </button>
      <p
        className="w-8 text-base text-nowrap font-bold text-center"
        data-testid="quantity"
        tabIndex={0}
        aria-label={t("quantity_for_item_x", { x: quantity })}
      >
        {quantity}
      </p>
      <button
        className={classNames("p-2 md:p-4", { "text-gray-400": !onIncrement })}
        onClick={onIncrement}
        disabled={!onIncrement || isLoading}
        data-testid="plus-button"
        aria-label={t("increase_quantity")}
        role="button"
      >
        <IconPlusFilled />
      </button>
    </div>
  );
}
