import type { SourceProduct, SourceType } from "@/generated/requests/pos";
import { formatMoney } from "@/static/lib/util";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import IconCalendarClock from "../../atoms/Icons/CalendarClock";
import IconCheck from "../../atoms/Icons/Check";
import IconPlusFilled from "../../atoms/Icons/PlusFilled";
import IconTimesFilled from "../../atoms/Icons/TimesFilled";
import IconTrophyStarRegular from "../../atoms/Icons/TrophyStarRegular";
import Text from "../../atoms/Text/Text";
import { useCustomerContext } from "../../contexts/CustomerContext/CustomerContext";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { verifyAreFlavorsAvailable } from "../../contexts/OrderContextNew/helpers";
import { useRewardsContext } from "../../contexts/RewardsContext/RewardsContext";
import Button from "../../molecules/Button/Button";
import { useGetSource, useGetStoreById, useGetStoreBySlug } from "../../operations/queries";
import { UpdateGiftWrapModal } from "../GiftWrapModal/GiftWrapModal";
import dayjs from "dayjs";
import OrderTimeAndLocationInfo from "../OrderTimeAndLocationInfo/OrderTimeAndLocationInfo";
import Divider from "../../atoms/Divider/Divider";
import CartOrderItemsList from "../CartOrderItemsList.tsx/CartOrderItemsList";

export default function CartSlideOut({ onDismiss = null }) {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    order,
    upsertOrderItems,
    lastRemovedItem,
    orderLoading,
    isUpsertLoading,
    orderTimeSlot,
    fulfillmentTimeMessage,
    checkFulfillmentTime,
    setTimeSlot,
    isLargeOrder,
    clearOrder,
  } = useOrderContextNew();
  const { customer } = useCustomerContext();
  const { rewardSummary } = useRewardsContext();
  const storeSlug = router.query.slug as string;
  const orderType = router.query.type as string;
  const orderSourceType = orderType?.toUpperCase() as SourceType;
  const { data: storeData } = useGetStoreBySlug({ storeSlug });
  // in the case where someone opens their bag, not on an order page, we use this store slug from the orders ID to navigate to the checkout page
  const { data: storeByIdData } = useGetStoreById({ storeId: order?.storeId });
  // Sending people to the right time slot to order
  router.query?.date && typeof router.query.date === "string" && setTimeSlot(dayjs(router.query.date).toISOString());
  const { data: sourceProductsData, loading: sourceProductsLoading } = useGetSource({
    storeId: storeData?.storeId || order?.storeId,
    type: orderSourceType || order?.source?.type,
    timeSlot: orderTimeSlot,
  });

  // if the order items aren't available for the current time slot, clear the order
  if (order?.items?.length && sourceProductsData?.public?.sourceForStore?.products?.length) {
    const areCurrFlavorsAvailable = verifyAreFlavorsAvailable({
      orderItems: order?.items,
      sourceProducts: sourceProductsData?.public?.sourceForStore?.products as SourceProduct[],
    });
    if (!areCurrFlavorsAvailable) {
      clearOrder({ saveTimeSlot: true, saveDeliveryAddress: true, saveIsLargeOrder: isLargeOrder });
    }
  }

  const [showUpdateGiftWrapModal, setShowUpdateGiftWrapModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const [selectedGiftWrapItemToUpdate, setSelectedGiftWrapItemToUpdate] = useState(null);

  const loyaltyTier = rewardSummary?.currentTier;
  const pointsPerDollarSpent = rewardSummary?.currentTier?.pointsPerDollarSpent;
  const upsellItemsToShow = order?.upsell?.items?.filter(
    (upsellItem) =>
      !order?.items?.find((orderItem) => orderItem.product.productId === upsellItem.productId) &&
      sourceProductsData?.public?.sourceForStore?.products?.find(
        (sourceProduct) => sourceProduct.product?.productId === upsellItem?.productId,
      ),
  );

  const subtotal = order?.totals?.subtotal?.amount;
  const crumbsToEarn = Math.floor((subtotal * pointsPerDollarSpent) / 100);

  useEffect(() => {
    if (showUpdateGiftWrapModal) {
      document.body.classList.add("update-gift-wrap-modal-open");
    } else {
      document.body.classList.remove("update-gift-wrap-modal-open");
    }
  }, [showUpdateGiftWrapModal]);

  const handleDismiss = () => {
    onDismiss?.();
  };

  // make sure when the component is opened and re-opened, the checkout btn should never be disabled
  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleCheckout = async (path: string) => {
    setIsLoading(true);
    try {
      await checkFulfillmentTime({
        callback: async () => {
          return await router.push(path);
        },
        source: sourceProductsData?.public?.sourceForStore,
        storeId: storeData?.storeId,
      });
    } catch (e) {
      console.error("error checking fulfillment ", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showToast) {
      const timeout = setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showToast]);

  const undoLastRemoveItemAction = () => {
    upsertOrderItems({ order, items: [...(order?.items || []), lastRemovedItem] });
    setShowToast(false);
  };

  const itemRemovedToast = () => {
    return (
      <div
        className={`flex w-[350px] sm:w-[400px] justify-between fixed left-1/2 translate-x-[-50%] ${isCartEmpty ? "bottom-10" : "bottom-28"} bg-secondary p-3 rounded-md z-10 animate-fadeIn`}
      >
        <div className="flex items-center gap-3 font-bold">
          <IconCheck width={24} height={24} />
          <span>{t("order:item_removed")}</span>
        </div>
        <button
          onClick={() => {
            undoLastRemoveItemAction();
            setShowToast(false);
          }}
          className="underline"
        >
          {t("order:undo")}
        </button>
      </div>
    );
  };
  const isCartEmpty = order?.items?.length === 0;

  return (
    <div id="cart" className="w-full h-full overflow-y-hidden relative flex flex-col lg:gap-[20px]">
      {showToast && itemRemovedToast()}

      {/* Cart Header section */}
      <div className="flex items-center justify-between pb-[10px] px-[30px] pt-[30px] bg-primary lg:bg-white">
        <Text variant="display3" tabIndex={0} role="text">
          {t("order:my_bag")}
        </Text>
        <IconTimesFilled
          width={34}
          height={34}
          onClick={handleDismiss}
          className="cursor-pointer hidden lg:block"
          tabIndex={0}
          aria-label={t("order:close_bag")}
          role="button"
        />
      </div>

      {/* Cart component content */}
      <div className="w-full h-full overflow-y-auto relative px-[15px] py-[15px] lg:py-0 md:px-[30px] pb-[250px] overflow-x-hidden flex flex-col gap-[15px]">
        <div className="flex flex-col gap-[15px] lg:hidden">
          <OrderTimeAndLocationInfo />
          <Divider />
        </div>

        {!isCartEmpty ? (
          <div className="flex flex-col gap-[15px] mb-[100px]">
            <CartOrderItemsList setShowToast={setShowToast} />
            <Divider />
            {upsellItemsToShow?.length > 0 && !!order?.upsell?.title && (
              <div className="flex flex-col gap-3">
                <Text variant="title3">{t(order.upsell.title)}</Text>
                <div className="w-full overflow-x-auto">
                  <div className="flex items-start gap-[15px]">
                    {upsellItemsToShow.map((upsellItem) => {
                      const priceDisplay = formatMoney(upsellItem.price);
                      const sourceProduct = sourceProductsData?.public?.sourceForStore?.products?.find(
                        (sourceProduct) => sourceProduct.product?.productId === upsellItem?.productId,
                      );
                      return (
                        <button
                          key={upsellItem.productId}
                          onClick={() =>
                            upsertOrderItems({
                              order,
                              items: [
                                ...(order?.items || []),
                                {
                                  productId: sourceProduct?.product?.productId,
                                  modifiers: upsellItem?.applyModifiers,
                                  quantity: 1,
                                },
                              ],
                            })
                          }
                          className={classNames("w-[90px] max-w-[90px] text-left animate-fadeInSlow", {
                            "opacity-50 cursor-not-allowed": orderLoading || isUpsertLoading,
                          })}
                          disabled={orderLoading || isUpsertLoading}
                          aria-label={t("order:add_upsell_item", { name: upsellItem.name, price: priceDisplay })}
                        >
                          <div className="w-[90px] h-[90px] min-w-[90px] min-h-[90px] rounded-lg bg-[#FFB9CD] flex items-center justify-center relative mb-[5px]">
                            <Image
                              src={upsellItem.image}
                              alt={upsellItem.name}
                              className="rounded-lg"
                              fill={true}
                              style={{
                                objectFit: "cover",
                              }}
                              priority
                            />
                            <div className="w-[34px] h-[34px] absolute top-1 right-1 bg-white rounded-lg flex items-center justify-center cursor-pointer">
                              <IconPlusFilled width={24} height={24} />
                            </div>
                          </div>
                          <Text variant="lineItem">{upsellItem.name}</Text>
                          <Text variant="finePrint">{priceDisplay}</Text>
                        </button>
                      );
                    })}
                    {/* Empty element for spacing overflow scroll */}
                    <div>
                      <div className="w-[15px] h-[15px]" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center justify-between" tabIndex={0} role="text">
              <Text variant="title3">{t("order:subtotal")}</Text>
              <div
                className={classNames({
                  "w-[100px] h-[20px] md:w-[70px] bg-neutral-200 rounded-md animate-pulse":
                    orderLoading || isUpsertLoading,
                })}
              />
              <div className={classNames({ hidden: orderLoading || isUpsertLoading })}>
                <Text variant="title3">{formatMoney(subtotal)}</Text>
              </div>
            </div>
            {!customer && (
              <Link
                href={{
                  pathname: "/login",
                  query: {
                    next: router.asPath,
                  },
                }}
                className={classNames(`w-full rounded-lg py-[10px] px-[15px] flex items-center bg-primary-light`)}
                tabIndex={0}
                role="link"
              >
                <IconTrophyStarRegular className="mr-[8px]" />
                <Text variant="smallHeader">{t("order:sign_in_to_earn_crumbs")}</Text>
              </Link>
            )}
            {!!customer && !!loyaltyTier && !!crumbsToEarn && (
              <div
                style={{ background: loyaltyTier.backgroundColor }}
                className={classNames(`w-full rounded-lg py-[10px] px-[15px] flex items-center`)}
                tabIndex={0}
                role="text"
              >
                <Image
                  src={loyaltyTier.badgeImageUrl}
                  alt={t("common:loyalty_tier")}
                  width={28}
                  height={28}
                  className="mr-[10px]"
                  aria-label={t("common:loyalty_tier_x", { x: loyaltyTier.name })}
                />
                <Text variant="smallHeader">{t("order:earn_x_crumbs_per_dollar", { x: pointsPerDollarSpent })}</Text>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full flex flex-col content-center items-center mt-20">
            <Image src={"/images/v2/noItemInCart.png"} alt={""} width={222} height={222} />
            <Text variant="title2" className="mt-[20px]">
              {t("order:nothing_here")}
            </Text>
            <Text variant="body2">{t("order:continue_add_items_to_bag")}</Text>
          </div>
        )}
      </div>

      {/* Checkout btn section */}
      {!isCartEmpty && (
        <div className="w-full z-10 left-0 fixed bottom-0 md:absolute px-[30px] pb-[30px] flex flex-col gap-[5px] items-center pt-[15px] bg-gradient-to-b from-transparent to-white to-50%">
          {!!fulfillmentTimeMessage && (
            <div className="sm:hidden flex items-center gap-[5px]">
              <IconCalendarClock height="24" width="24" />
              <Text>{fulfillmentTimeMessage}</Text>
            </div>
          )}
          <Button
            id="desktopCheckoutButton"
            className="w-full hidden lg:block"
            onClick={() =>
              handleCheckout(
                orderType && storeSlug
                  ? `/order/${orderType}/${storeSlug}/checkout`
                  : order?.source?.type && order?.storeId
                    ? `/order/${order?.source?.type?.toLowerCase()}/${storeByIdData?.slug}/checkout`
                    : "/order",
              )
            }
            disabled={isLoading}
          >
            {t("order:checkout")}
          </Button>
          <Button
            id="mobileCheckoutButton"
            className="w-full lg:hidden"
            onClick={() =>
              handleCheckout(
                orderType && storeSlug
                  ? `/order/${orderType}/${storeSlug}/checkout/note`
                  : order?.source?.type && order?.storeId
                    ? `/order/${order?.source?.type?.toLowerCase()}/${storeByIdData?.slug}/checkout/note`
                    : "/order",
              )
            }
            disabled={isLoading}
          >
            {t("order:checkout")}
          </Button>
        </div>
      )}

      {showUpdateGiftWrapModal && selectedGiftWrapItemToUpdate && (
        <UpdateGiftWrapModal
          itemToUpdate={selectedGiftWrapItemToUpdate}
          isOpen={showUpdateGiftWrapModal}
          onClose={() => {
            setShowUpdateGiftWrapModal(false);
            setSelectedGiftWrapItemToUpdate(null);
          }}
          isUpdate={true}
        />
      )}
    </div>
  );
}
