import { useRouter } from "next/router";
import ButtonText from "../../molecules/ButtonText/ButtonText";
import OrderInfoHeaderItem from "../OrderInfo/OrderInfoHeaderItem";
import { useTranslation } from "next-i18next";
import IconAlarmClockFilled from "../../atoms/Icons/AlarmClockFilled";
import PreOrderTag from "../../atoms/PreOrderTag/PreOrderTag";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { getTimeAndAddressLabelsForOrder } from "../../contexts/OrderContextNew/helpers";
import { useGetStoreBySlug } from "../../operations/queries";
import IconStoreFilled from "../../atoms/Icons/StoreFilled";

export default function OrderTimeAndLocationInfo({ hideEditButton = false }: { hideEditButton?: boolean }) {
  const router = useRouter();
  const { t } = useTranslation();
  const { order, orderTimeSlot, deliveryAddress, isLargeOrder } = useOrderContextNew();
  const storeSlug = router.query.slug as string;
  const orderType = router.query.type as string;
  const { data: storeData } = useGetStoreBySlug({ storeSlug });

  const labelsObj =
    order && storeData
      ? getTimeAndAddressLabelsForOrder({
          order,
          t,
          router,
          // @ts-ignore
          store: storeData,
          deliveryAddress,
          orderTimeSlot,
          isLargeOrder,
        })
      : {
          dateLabel: "",
          timeLabel: "",
          dateAndTimeLabel: "",
          IconForOrderType: IconStoreFilled,
          locationLabel: "",
          addressLabel: "",
          typeLabel: "",
        };
  const locationLabel = labelsObj?.locationLabel;
  const addressLabel = labelsObj?.addressLabel;
  const IconForOrderType = labelsObj?.IconForOrderType;
  const dateLabel = labelsObj?.dateLabel;
  const timeLabel = labelsObj?.timeLabel;

  return (
    <div className="lg:hidden flex flex-col items-start gap-2.5">
      <div className="flex justify-between w-full">
        <div className="flex items-center gap-[5px]">
          <OrderInfoHeaderItem
            Icon={IconAlarmClockFilled}
            primaryText={dateLabel || ""}
            secondaryText={timeLabel || ""}
            preOrderTag={<PreOrderTag />}
            showDropDown={false}
            className="hover:cursor-default"
          />
        </div>
        {!hideEditButton && (
          <ButtonText
            className="text-grey-60 underline-offset-2 lg:hidden"
            onClick={() => router.push(`/order/${orderType}/${storeSlug}`)}
          >
            {t("order:edit")}
          </ButtonText>
        )}
      </div>
      <div className="w-full">
        <OrderInfoHeaderItem
          Icon={IconForOrderType}
          primaryText={locationLabel || ""}
          secondaryText={addressLabel || ""}
          showDropDown={false}
          className="hover:cursor-default"
        />
      </div>
    </div>
  );
}
